import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ISort, ISortOption } from 'src/app/models/sort.model';

@Component({
  selector: 'app-sorting-segment',
  templateUrl: './sorting-segment.component.html',
  styleUrl: './sorting-segment.component.scss',
})
export class SortingSegmentComponent {
  @Output() sortOptionSelected = new EventEmitter<ISortOption>();

  public selectedOption: ISortOption = {
    sortBy: 'createdAt',
    sortOrder: 'desc',
  };

  public selectOption(option: string): void {
    this.selectedOption.sortBy = option;
    this.sortOptionSelected.emit(this.selectedOption);
  }
}
