import { CommonModule, DatePipe } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FlatpickrModule } from 'angularx-flatpickr';
import { NgxRerenderModule } from 'ngx-rerender';
import { ToastrModule } from 'ngx-toastr';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { BadgeComponent } from './components/badge/badge.component';
import { CompanyGoalsComponent } from './components/company-goals/company-goals.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { EditOvertimeComponent } from './components/edit-overtime/edit-overtime.component';
import { FeedbackListComponent } from './components/feedbacks/feedback-list.component';
import { HeaderComponent } from './components/header/header.component';
import { HistoryDialogComponent } from './components/history-dialog/history-dialog.component';
import { LeaveDialogComponent } from './components/leave-dialog/leave-dialog.component';
import { ManageEmployeeDialogComponent } from './components/manage-employee-dialog/manage-employee-dialog.component';
import { ManageLibraryDialogComponent } from './components/manage-library-dialog/manage-library-dialog.component';
import { NavigationBoxComponent } from './components/navigation-box/navigation-box.component';
import { ProductIdeaListComponent } from './components/product-idea-list/product-idea-list.component';
import { RevenueComponent } from './components/revenue/revenue.component';
import { ShopDialogComponent } from './components/shop-dialog/shop-dialog.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { SortingSegmentComponent } from './components/sorting-segment/sorting-segment.component';
import { TextContentBoxComponent } from './components/text-content-box/text-content-box.component';
import { UserOverviewBoxComponent } from './components/user-overview-box/user-overview-box.component';
import { ValueBoxComponent } from './components/value-box/value-box.component';
import { EmployeeRowComponent } from './components/working-time/employee-row/employee-row.component';
import { WorkingTimeComponent } from './components/working-time/working-time.component';
import { JwtInterceptor } from './general/jwt.interceptor';
import { AdminComponent } from './pages/admin/admin.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { FeedbackComponent } from './pages/feedback/feedback.component';
import { HolidayPlanning } from './pages/holiday-planning/holiday-planning.component';
import { InfosComponent } from './pages/infos/infos.component';
import { LibraryComponent } from './pages/library/library.component';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { MissionValuesComponent } from './pages/mission-values/mission-values.component';
import { ProductIdeasComponent } from './pages/product-ideas/product-ideas.component';
import { RevenuesComponent } from './pages/revenues/revenues.component';
import { ShopComponent } from './pages/shop/shop.component';
import { TrainingComponent } from './pages/training/training.component';
import { FilterByCategoryPipe } from './pipes/filter-by-category.pipe';
import { FilterByNamePipe } from './pipes/filter-by-name.pipe';
import { NgxPiwikProModule, NgxPiwikProRouterModule } from '@piwikpro/ngx-piwik-pro';
import { environment } from 'src/environments/environment';
import * as Sentry from '@sentry/angular';
import { Router } from '@angular/router';
import { TooltipModule } from 'ng2-tooltip-directive';
import { ManageProductDialogComponent } from './components/manage-product-dialog/manage-product-dialog.component';
import { PersonnelFileComponent } from './pages/personnel-file/personnel-file.component';
import { ProductComponent } from './components/product/product.component';
import { AdminSickLeavesComponent } from './pages/admin/admin-sick-leaves/admin-sick-leaves.component';
import { FeedbackCardComponent } from './components/feedbacks/feedback-card/feedback-card.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginPageComponent,
    DashboardComponent,
    HeaderComponent,
    SidebarComponent,
    AdminComponent,
    AdminSickLeavesComponent,
    HolidayPlanning,
    EditOvertimeComponent,
    LeaveDialogComponent,
    ConfirmDialogComponent,
    ManageEmployeeDialogComponent,
    EmployeeRowComponent,
    RevenueComponent,
    FeedbackListComponent,
    RevenuesComponent,
    CompanyGoalsComponent,
    ProductIdeasComponent,
    ProductIdeaListComponent,
    BadgeComponent,
    MissionValuesComponent,
    ValueBoxComponent,
    TextContentBoxComponent,
    LibraryComponent,
    ManageLibraryDialogComponent,
    InfosComponent,
    SortingSegmentComponent,
    NavigationBoxComponent,
    UserOverviewBoxComponent,
    WorkingTimeComponent,
    FilterByNamePipe,
    ShopComponent,
    ShopDialogComponent,
    DropdownComponent,
    TrainingComponent,
    ConfirmationDialogComponent,
    HistoryDialogComponent,
    ManageProductDialogComponent,
    PersonnelFileComponent,
    ProductComponent,
    FeedbackComponent,
    FeedbackCardComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    CommonModule,
    BrowserAnimationsModule,
    NgxRerenderModule,
    FontAwesomeModule,
    TooltipModule,
    ToastrModule.forRoot({
      positionClass: 'toast-top-right',
      timeOut: 2000,
      easing: 'ease-in-out',
      easeTime: 300,
    }),
    FlatpickrModule.forRoot(),
    ...(environment.activatePiwik
      ? [
          NgxPiwikProModule.forRoot('6de10c1e-0135-4d4e-baeb-4b1cee9547f6', 'https://42projects.piwik.pro'),
          NgxPiwikProRouterModule,
        ]
      : []),
    ...(environment.activatePiwik
      ? [
          NgxPiwikProModule.forRoot('6de10c1e-0135-4d4e-baeb-4b1cee9547f6', 'https://42projects.piwik.pro'),
          NgxPiwikProRouterModule,
        ]
      : []),
  ],
  providers: [
    DatePipe,
    FilterByCategoryPipe,
    FilterByNamePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
