<div class="relative flex items-center justify-center gap-4 max-md:flex-col">
  <app-sorting-segment (sortOptionSelected)="sortOptionSelected($event)"></app-sorting-segment>
  @if (!router.url.includes('/dashboard')) {
    <div class="right-0 flex gap-4 md:absolute">
      <label class="text-sm font-medium text-black">Closed</label>
      <input
        type="checkbox"
        class="flex h-5 w-9 min-w-[2rem] appearance-none items-center rounded-full bg-gray-300 shadow-inner transition-all duration-300 before:ml-0.5 before:inline-block before:h-4 before:w-4 before:rounded-full before:bg-sunflowerGold checked:bg-yellow-200 checked:before:translate-x-full focus:outline-none"
        data-cy="feedback-solved-toggle"
        (change)="toggleSolvedFeedbacks()"
      />
    </div>
  }
</div>

@if (feedbacks.length === 0) {
  <div class="mt-8 flex items-center justify-center">
    <span class="text-center text-sm font-semibold text-black">There are currently no feedbacks</span>
  </div>
} @else {
  <div data-cy="feedback-list">
    @for (feedback of feedbacks; track feedback; let i = $index) {
      <app-feedback-card
        [feedback]="feedback"
        [isHomePage]="isHomePage"
        (feedbackRemoved)="onFeedbackRemoved($event)"
      ></app-feedback-card>
    }
  </div>
}
