export interface IFeedback {
  feedbacks: IFeedback[];
  _id?: string;
  text: string;
  voteCount: number;
  hasVoted: string[];
  isSolved: boolean;
  category: FeedbackCategory;
  createdAt: Date;
  color?: string;
  comments?: IFeedbackComment[];
  fileUrl?: string;
}

export interface IFeedbackComment {
  _id?: string;
  comment: string;
  commentedBy?: string | null;
  commentedAt: Date;
}

export enum FeedbackCategory {
  SAVING = 'saving',
  IMPROVEMENT = 'improvement',
  FEEDBACK = 'feedback',
  TOOLS = 'tools',
  OTHER = 'other',
}
