import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FeedbackCategory, IFeedback } from 'src/app/models/feedback.model';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { FeedbackService } from 'src/app/services/feedback.service';
import { Subscription } from 'rxjs';
import { IConfirmData } from 'src/app/models/confirm-data';
import { faCommentDots, faLink } from '@fortawesome/free-solid-svg-icons';
import { HelperService } from 'src/app/services/helper.service';

@Component({
  selector: 'app-feedback-card',

  templateUrl: './feedback-card.component.html',
  styleUrl: './feedback-card.component.scss',
})
export class FeedbackCardComponent {
  @Input() feedback: IFeedback;
  @Input() isHomePage = false;
  @Output() feedbackRemoved = new EventEmitter<IFeedback>();

  public newComment = '';
  public showComments = false;
  public confirmDialog = false;
  private subscription = new Subscription();
  public modalData = {} as IConfirmData;
  public commentIcon = faCommentDots;
  public faLink = faLink;

  constructor(
    private router: Router,
    public authService: AuthService,
    private toastr: ToastrService,
    private feedbackService: FeedbackService,
    public helperService: HelperService,
  ) {}

  public isShowSolveButton(): boolean {
    return this.authService.getCurrentEmployee().isAdmin && !this.router.url.includes('/dashboard');
  }

  public voteFeedback(feedback: IFeedback, voteType: 'up' | 'down'): void {
    const employeeId = this.authService.getCurrentEmployee()?._id;

    const userHasVoted = this.feedback.hasVoted.includes(employeeId);
    if (userHasVoted) {
      this.toastr.error('You have already voted');
    } else {
      const newCount = voteType === 'up' ? feedback.voteCount + 1 : feedback.voteCount - 1;
      this.setVoteAndUpdateFeedbacks(newCount);
    }
  }

  private setVoteAndUpdateFeedbacks(newVoteCount: number): void {
    this.subscription.add(
      this.feedbackService.voteFeedback(this.feedback._id, newVoteCount).subscribe({
        next: () => {
          this.feedback.voteCount = newVoteCount;
          this.toastr.success('Feedback was successfully voted');
        },
        error: () => {
          this.toastr.error('Failed to vote on feedback');
        },
      }),
    );
  }

  public resolveFeedback(): void {
    this.subscription.add(
      this.feedbackService.setSolvedFeedback(this.feedback._id).subscribe({
        next: () => {
          this.feedbackRemoved.emit(this.feedback);
          this.toastr.success('Feedback was successfully set to solved');
        },
        error: () => {
          this.toastr.error('Something went wrong');
        },
      }),
    );
  }
  public deleteFeedback(): void {
    this.subscription.add(
      this.feedbackService.deleteFeedback(this.feedback._id).subscribe({
        next: () => {
          this.feedbackRemoved.emit(this.feedback);
          this.toastr.success('Feedback was deleted successfully');
        },
        error: () => {
          this.toastr.error('Something went wrong');
        },
      }),
    );
  }

  public onDelteModalConfirmation(): void {
    if (!this.feedback.isSolved) {
      this.resolveFeedback();
    } else {
      this.deleteFeedback();
    }
  }

  public onCreateComment(): void {
    /*     this.feedback.comments.push(this.newComment); */
    this.subscription.add(
      this.feedbackService.createComment(this.feedback._id, this.newComment).subscribe({
        next: (res) => {
          this.feedback.comments = res.DATA.comments;
        },
        error: () => {
          this.toastr.error('Something went wrong');
        },
        complete: () => {
          this.newComment = '';
        },
      }),
    );
    console.log('update, ', this.feedback);
  }

  public createRemoveModal(): void {
    if (this.feedback.isSolved) {
      this.confirmDialog = true;
      this.modalData.headline = `Delete Feedback?`;
      this.modalData.text = `Are you sure yout want to delete this feedback?`;
      this.modalData.image = 'delete';
    } else {
      this.confirmDialog = true;
      this.modalData.headline = `Feedback solved?`;
      this.modalData.text = `Are you sure you want to set this feedback to solved?`;
      this.modalData.image = 'feedback';
    }
  }

  public getFeedbackCategoryColor(category: FeedbackCategory): string {
    switch (category) {
      case FeedbackCategory.OTHER:
        return 'red';
      case FeedbackCategory.SAVING:
        return 'green';
      case FeedbackCategory.IMPROVEMENT:
        return 'blue';
      case FeedbackCategory.FEEDBACK:
        return 'orange';
      case FeedbackCategory.TOOLS:
        return 'purple';
      default:
        return 'black';
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
