<div class="relative flex items-center justify-center gap-1 rounded p-[2px] text-white">
  <div
    [ngClass]="{
      'bg-[#333333]': selectedOption.sortBy === 'createdAt',
      'bg-gray-300': selectedOption.sortBy !== 'createdAt',
    }"
    (click)="selectOption('createdAt')"
    class="w-24 cursor-pointer rounded px-2 py-1 text-center text-xs transition-all duration-300 sm:w-36 sm:text-sm"
    data-cy="sort-latest"
  >
    <span>Latest</span>
  </div>
  <div
    [ngClass]="{
      'bg-[#333333]': selectedOption.sortBy === 'voteCount',
      'bg-gray-300': selectedOption.sortBy !== 'voteCount',
    }"
    (click)="selectOption('voteCount')"
    class="w-24 cursor-pointer rounded px-2 py-1 text-center text-xs transition-all duration-300 sm:w-36 sm:text-sm"
    data-cy="sort-most-popular"
  >
    <span>Most Popular</span>
  </div>
</div>
