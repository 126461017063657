<div data-cy="feedback-card" class="flex flex-col">
  <div class="center mb-1 flex w-full flex-col items-center pt-5 first:pt-2">
    @if (isShowSolveButton()) {
      <button daty-cy="feedback-solve-button" (click)="createRemoveModal()" class="z-10 -mb-4 flex bg-lightGrayish">
        @if (feedback.isSolved) {
          <img src="../../../assets/img/icons/delete.png" class="h-7" alt="delete icon" />
        } @else {
          <img src="../../../assets/img/icons/solve.png" class="h-8" alt="solve" />
        }
      </button>
    }
    <div
      class="relative flex w-full flex-col items-center border-2 border-sunflowerGold bg-paleOrange p-3 max-lg:pt-5 max-lg:text-xs lg:p-5"
      data-cy="feedback-item"
    >
      {{ feedback.text }}
      <div class="mt-5 flex w-full items-center justify-center gap-1 max-sm:relative max-sm:flex-col-reverse">
        <div class="left-5 flex items-center gap-4 max-md:w-full md:absolute">
          <app-badge
            [dataCategory]="feedback.category"
            [isHomePage]="isHomePage"
            class="bg-{{ getFeedbackCategoryColor(feedback.category) }}-500 flex items-center h-[30px] rounded white"
            data-cy="feedback-badge"
          ></app-badge>

          <button
            (click)="showComments = !showComments"
            class="flex items-center gap-2"
            data-cy="feedback-comments-symbol"
          >
            <fa-icon class="lg:scale-125" [icon]="commentIcon"></fa-icon>
            <span class="text-xs lg:text-base"> {{ feedback.comments.length }}</span>
          </button>
        </div>

        <div class="flex items-center">
          <div class="flex items-center gap-2">
            @if (!feedback.isSolved) {
              <button (click)="voteFeedback(feedback, 'down')" data-cy="fb-arrow-down">
                <img class="h-7 rotate-180" src="../../../assets/img/icons/arrow-up.png" alt="Arrow up" />
              </button>
            }

            <p class="font-bold lg:text-2xl" data-cy="feedback-vote-count">{{ feedback.voteCount }}</p>
            @if (!feedback.isSolved) {
              <button (click)="voteFeedback(feedback, 'up')" data-cy="fb-arrow-up">
                <img class="h-7" src="../../../assets/img/icons/arrow-up.png" alt="Arrow up" />
              </button>
            }
          </div>
        </div>
        @if (feedback.fileUrl) {
          <a class="absolute bottom-0 right-4 md:bottom-4" [href]="feedback.fileUrl" target="_blank">
            <fa-icon
              tooltip="Open documents"
              [showDelay]="250"
              class="absolute bottom-2 right-0 flex cursor-pointer items-center lg:right-2 lg:scale-125"
              data-cy="feedback-documents-link"
              [icon]="faLink"
            ></fa-icon>
          </a>
        }
      </div>
    </div>
    @if (showComments) {
      <div class="w-full border-x-2 border-b-2 border-sunflowerGold bg-paleOrange" data-cy="feedback-comments-list">
        @for (comment of feedback.comments; track comment; let last = $last) {
          <div
            class="mx-4 flex justify-between bg-paleOrange py-2 text-xs lg:text-base"
            data-cy="feedback-single-comment"
            [ngClass]="{ 'border-b border-white': !last }"
          >
            <span class="w-11/12 break-words">{{ comment.comment }}</span>
          </div>
        }
        <div class="relative flex items-center bg-paleOrange">
          <input
            class="no-focus h-10 w-full resize-none p-2 text-xs outline-none placeholder:text-xs"
            data-cy="feedback-comment-textarea"
            placeholder="Write a comment..."
            [(ngModel)]="newComment"
          />

          <button
            class="flex h-10 w-12 items-center justify-center bg-white p-1"
            (click)="onCreateComment()"
            data-cy="feedback-send-comment-button"
          >
            <img class="h-4" src="../../../assets/img/icons/send.png" alt="send" />
          </button>
        </div>
      </div>
    }
  </div>

  @if (confirmDialog) {
    <app-confirm-dialog
      (closeDialog)="confirmDialog = false"
      (saveChanges)="onDelteModalConfirmation()"
      [data]="modalData"
    ></app-confirm-dialog>
  }
</div>
