import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { UserOverviewType } from 'src/app/enum/user-overview-type.enum';
import { IEmployee } from 'src/app/models/employee.model';
import { ILeave } from 'src/app/models/leave.model';
import { EmployeeResponse } from 'src/app/models/response.model';
import { AuthService } from 'src/app/services/auth.service';
import { EmployeeService } from 'src/app/services/employee.service';
import { LeaveService } from 'src/app/services/leave.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  public mySortedLeaves: ILeave[] = [];
  private subscriptions = new Subscription();
  public leavesOfToday: ILeave[] = [];
  public passwordDialog = false;

  public employeeBirthdayData = {} as IEmployee;
  public UserOverviewType = UserOverviewType;

  constructor(
    private leaveService: LeaveService,
    private employeeService: EmployeeService,
    public authService: AuthService,
    private toastr: ToastrService,
  ) {}

  ngOnInit(): void {
    this.checkEmployeeFirstLogin();
    this.getLeavesOfToday();
    this.fetchNextBirthday();
  }

  private fetchNextBirthday(): void {
    this.subscriptions.add(
      this.employeeService.getNextBirthday().subscribe({
        next: (res: EmployeeResponse) => {
          this.employeeBirthdayData = res['DATA'];
        },
        error: () => {
          this.toastr.error('Error loading next birthday');
        },
      }),
    );
  }

  private checkEmployeeFirstLogin(): void {
    const employee = this.authService.getCurrentEmployee();
    if (employee && employee.firstLogin) {
      this.passwordDialog = true;
      this.toastr.success('Please Enter a new Password');
    }
  }

  private getLeavesOfToday(): void {
    this.subscriptions.add(
      this.leaveService.getLeavsOfToday().subscribe({
        next: (res) => {
          this.leavesOfToday = res.DATA;
        },
        error: (err) => {
          console.log(err);
          this.toastr.error('Error loading leaves');
        },
      }),
    );
  }

  public navigateToUrl(url: string): void {
    window.open(url, '_blank');
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
