import { Component, ViewChild } from '@angular/core';
import { ActiveToast, ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { FeedbackListComponent } from 'src/app/components/feedbacks/feedback-list.component';
import { FeedbackCategory, IFeedback } from 'src/app/models/feedback.model';
import { FeedbackResponse, FeedbacksResponse } from 'src/app/models/response.model';
import { ISortOption } from 'src/app/models/sort.model';
import { FeedbackService } from 'src/app/services/feedback.service';
import { HelperService } from 'src/app/services/helper.service';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss'],
})
export class FeedbackComponent {
  @ViewChild(FeedbackListComponent) feedbackListComponent!: FeedbackListComponent;
  private subscription = new Subscription();

  public FeedbackCategory = FeedbackCategory;

  public feedback = {} as IFeedback;

  constructor(
    private helperService: HelperService,
    private feedbackSerivce: FeedbackService,
    private toastr: ToastrService,
  ) {}

  public onBadgeClick(category: FeedbackCategory) {
    this.feedback.category = category;
  }

  public handleCreateFeedback(): ActiveToast<Error> | void {
    if (!this.feedback.category) {
      return this.toastr.error('Please select one of the options!');
    }
    if (!this.feedback.text.trim()) {
      return this.toastr.error('Text field is empty!');
    }
    this.createNewFeedback();
  }

  private createNewFeedback(): void {
    this.subscription.add(
      this.feedbackSerivce.createFeedback(this.feedback).subscribe({
        next: (res: FeedbackResponse) => {
          this.feedbackListComponent.getAllFeedbacks();
          this.feedback = {} as IFeedback;
          this.toastr.success('Feedback was successfully added');
        },
        error: () => {
          this.toastr.error('Failed to create a new feedback');
        },
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
