import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { IFeedback } from 'src/app/models/feedback.model';
import { FeedbacksResponse } from 'src/app/models/response.model';
import { ISortOption } from 'src/app/models/sort.model';
import { FeedbackService } from 'src/app/services/feedback.service';

@Component({
  selector: 'app-feedback-list',
  templateUrl: './feedback-list.component.html',
  styleUrls: ['./feedback-list.component.scss'],
})
export class FeedbackListComponent {
  @Input() isHomePage = false;

  private subscription = new Subscription();
  public selectedSortOption: ISortOption = {
    sortBy: 'createdAt',
    sortOrder: 'desc',
  };

  public showSolvedFeedbacks = false;
  public confirmDialog = false;

  public currentFeedback = {} as IFeedback;
  public feedbacks: IFeedback[] = [];

  constructor(
    private feedBackService: FeedbackService,
    private toastr: ToastrService,
    public router: Router,
  ) {}

  ngOnInit(): void {
    this.getAllFeedbacks();
  }

  public sortOptionSelected(sortOption: ISortOption): void {
    this.selectedSortOption = sortOption;

    this.getAllFeedbacks();
  }

  public getAllFeedbacks(): void {
    this.subscription.add(
      this.feedBackService.getAllFeedbacks(this.selectedSortOption, this.showSolvedFeedbacks).subscribe({
        next: (res: FeedbacksResponse): void => {
          this.feedbacks = res.DATA;
        },
        error: () => {
          this.toastr.error('Something went wrong');
        },
      }),
    );
  }

  public toggleSolvedFeedbacks(): void {
    this.showSolvedFeedbacks = !this.showSolvedFeedbacks;
    this.getAllFeedbacks();
  }

  public onFeedbackRemoved(feedback: IFeedback): void {
    this.feedbacks = this.feedbacks.filter((f) => f._id !== feedback._id);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
